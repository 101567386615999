<template>
  <v-row class="match-height">
    <v-col>
      <Breadcrumbs :items="items" />
    </v-col>
    <v-col cols="12">
      <v-form
        ref="form"
        class="multi-col-validation"
        lazy-validation
      >
        <v-card>
          <v-card-title>{{ formTitulo }}</v-card-title>
          <v-row class="mx-5">
            <!-- Nombre -->
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                ref="refName"
                v-model="obj.name"
                label="Nombre"
                outlined
                dense
                placeholder="Nombre"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                ref="refusername"
                v-model="obj.userName"
                label="Usuario"
                outlined
                dense
                placeholder="Usuario"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                ref="refoperetionType"
                v-model="obj.operationType"
                label="Tipo de operación"
                outlined
                dense
                placeholder="Tipo de operación"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                ref="refcreatedAt"
                v-model="obj.createdAt"
                label="Fecha de la operación"
                outlined
                dense
                placeholder="Fecha de la operación"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
            >
              <v-textarea
                ref="refinitialValue"
                v-model="obj.initialValue"
                label="Valor inicial"
                outlined
                dense
                placeholder="Valor inicial"
              >
              </v-textarea>
            </v-col>
            <v-col
              cols="12"
            >
              <v-textarea
                ref="refinitialValue"
                v-model="obj.finalValue"
                label="Valor final"
                outlined
                dense
                placeholder="Valor final"
              >
              </v-textarea>
            </v-col>
          </v-row>

          <v-card-actions
            v-if="true"
            class="mt-5"
          >
            <v-row>
              <!-- Botones de acción -->
              <v-col cols="12">
                <v-btn
                  type="reset"
                  outlined
                  class="mx-2"
                  @click="cancelarForm"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiCalendar,
  mdiAlertOctagonOutline,
  mdiInformation,
} from '@mdi/js'
import { ref, reactive, computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import HTTP from '@/utils/axios/axios-config-base'

export default {
  components: {
    Breadcrumbs,
  },
  setup() {
    const keypublicWorksubCategory = ref(0)
    const items = reactive([
      {
        text: 'Inicio',
        disabled: false,
        href: '/',
      },
      {
        text: 'Obras',
        disabled: false,
        href: '/obras',
      },
      {
        text: 'Editar Obras',
        disabled: true,
        href: '/obras/editar-obras',
      },
    ])

    // tabs
    const tabs = reactive([
      {
        id: '1', title: 'Datos Generales', icons: mdiAlertOctagonOutline, error: false,
      },
      {
        id: '2', title: 'Ubicación', icons: mdiAlertOctagonOutline, error: false,
      },
      {
        id: '3', title: 'Logs del Sistema', icons: mdiAlertOctagonOutline, error: false,
      },

      // { id: '2', title: 'Requisitos / Tiempo' },
    ])
    const { router, route } = useRouter()
    const formTitulo = ref('Editar Obras')
    const detalleLogsId = route.value.params.id
    const obj = reactive({
      createAt: '',
      entityName: '',
      finalValue: '',
      initialValue: '',
      name: '',
      operationType: '',
      systemLogId: '',
      userName: '',
    })
    const form = ref(null)
    const refName = ref(null)

    // Errores
    const permissions = localStorage.getItem('permissions')
    const deshabilitarForm = computed(() => permissions.includes('Obras:Obras:FullAccess') || permissions.includes('Obras:Obras:ApproveAccess'))

    const detalleLogsById = async id => {
      try {
        if (id) {
          const URL = `${HTTP.defaults.baseURL}/identity-api/systemLogs/${id}`
          const resp = await HTTP.get(URL)
          obj.createdAt = resp.data.value.createdAt
          obj.entityName = resp.data.value.entityName
          obj.finalValue = resp.data.value.finalValue
          obj.initialValue = resp.data.value.initialValue
          obj.name = resp.data.value.name
          obj.operationType = resp.data.value.operationType
          obj.systemLogId = resp.data.value.systemLogId
          obj.userName = resp.data.value.userName
        }
      } catch (error) {}
    }
    detalleLogsById(detalleLogsId)

    // Logs del Sistema
    const url = ref(`${HTTP.defaults.baseURL}identity-api/SystemLogs`)

    return {
      detalleLogsId,
      items,
      tabs,
      form,
      refName,
      formTitulo,
      keypublicWorksubCategory,
      obj,
      detalleLogsById,

      // Logs del Sistema
      url,

      // iconos
      icons: {
        mdiCalendar,
        mdiAlertOctagonOutline,
        mdiInformation,
      },
      deshabilitarForm,
      cancelarForm: () => {
        router.push({ name: 'obras' })
      },

    }
  },
}
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}
.border {
  border: #e0e0e0 solid 1px;
  border-radius: 15px;
}

.border-tramite-activo {
  border: #185faa solid 2px;
}
.border-tramite {
  border: white solid 2px;
}
.border-tramite:hover {
  border: #185faa solid 2px;
}

.border-transiciones-activo {
  border: #4caf50 solid 2px;
}
.border-transiciones {
  border: white solid 2px;
}
.border-transiciones:hover {
  border: #4caf50 solid 2px;
}

.border-estado-activo {
  border: #fbad4b solid 2px;
}
.border-estado {
  border: white solid 2px;
}
.border-estado:hover {
  border: #fbad4b solid 2px;
}

.compact-form {
  transform: scale(0.7);
  transform-origin: left;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
  /* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.truncate_name {
    max-width: 12vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.truncate {
    max-width: 30vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
